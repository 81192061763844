import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { rgba } from 'polished'
import Slider from 'react-slick'

import { black } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import { animationTime, animationCurve } from 'styles/variables'
import { square } from 'styles/helpers'

import Icon from 'components/Icon'
import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'

const Container = styled.div`
  position: static;
`

const Name = styled(Heading)`
  position: relative;
  margin-bottom: 0.75em;

  ${media.medium`
    margin-bottom: 0.35em;
  `};
`

const Details = styled.div`
  position: static;
  padding-bottom: 10vw;

  ${media.medium`
    width: 30%;
  `};
`

const FigureWrap = styled.div`
  position: relative;
  padding-bottom: 66%;
  margin-bottom: 1.75em;
  outline: none;
  width: auto !important;

  img {
    margin-left: auto;
    margin-right: auto;
  }

  ${media.medium`
    position: fixed;
    left: 35%;
    right: 5%;
    top: 10%;
    bottom: 0;
    background-color: ${black};
    padding-bottom: 0;
    margin-bottom: 0;
  `};
`

const BackLink = styled(Link)`
  display: flex;
  margin-bottom: ${spacing.large};

  ${media.medium`
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: ${spacing.medium};
  `};

  ${media.xLarge`
    margin-right: ${spacing.large};
  `};
`

const Description = styled.p`
  position: static;

  ${media.medium`
    font-size: 0.85rem;
  `};
`

const Meta = styled.p`
  color: ${rgba(black, 0.5)};

  ${media.medium`
    font-size: 0.75rem;
  `};
`

const SliderWrap = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    position: static !important;
  }

  .slick-dots {
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0;
    list-style: none;
    margin-top: ${spacing.large};
    position: absolute;
    right: ${spacing.large};

    ${media.medium`
      margin-top: ${spacing.xLarge};
    `};

    li {
      margin: 0 3px;

      ${media.large`
        margin: 0 4px;
      `};
    }

    li.slick-active button {
      background-color: ${black};
    }

    button {
      font-size: 0;
      display: block;
      ${square('10px')};
      background-color: ${rgba(black, 0.25)};
      border-radius: 50%;
      transition: background-color ${animationTime} ${animationCurve};

      ${media.medium`
        ${square('12px')};
      `};
    }
  }
`

const slickSettings = {
  infinite: false,
  arrows: false,
  dots: true,
  fade: true,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: true,
  lazyLoad: 'ondemand'
}

const ProjectPage = ({ name, description, meta, images }) => (
  <Container>
    <Wrapper>
      <Name className="u-hide-medium-up">
        <BackLink to="/">
          <Icon icon="left-arrow" medium />
        </BackLink>
        {name}
      </Name>
      <SliderWrap>
        <Slider {...slickSettings}>
          {images.map((image, i) => (
            <FigureWrap key={i}>
              <Img fluid={image} alt={name} key={i} />
            </FigureWrap>
          ))}
        </Slider>
      </SliderWrap>
      <Details>
        <Name className="u-hide-medium-down">
          <BackLink to="/">
            <Icon icon="left-arrow" medium />
          </BackLink>
          {name}
        </Name>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        {meta && <Meta dangerouslySetInnerHTML={{ __html: meta }} />}
      </Details>
    </Wrapper>
  </Container>
)

ProjectPage.propTypes = {
  name: string,
  meta: string,
  description: string,
}

export default ProjectPage
